<template>
    <div class='d-flex align-items-center h-100'>
      <div class="stap-inner">
      <h1><span class='stap-number'>8.</span> Aanvullingen</h1>
        <h5 class='stap-beschrijving'>Heb je aanvullende informatie en/of bijzonderheden? </h5>
                <div class="form-topic">
                     <div class="form-group">
                      <textarea class="form-control" id="opmerking" rows="5" v-model.lazy="opmerking" placeholder="Vul deze informatie hier in."></textarea>
                      <button class='btn btn-primary btn-lg mt-3 me-auto'
                        @click="next()">
                        Volgende stap
                      </button>
                  </div>
            </div>
          <div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StapControle',
  mounted: function () {
    window.scrollTo(0, 0)
  },
  methods: {
    next: function () {
      this.$router.push({ name: 'StapControle' })
    }
  },
  computed: {
    opmerking: {
      get: function () {
        return this.$store.state.aanmelding.opmerking
      },
      set: function (value) {
        this.$store.dispatch('aanmelding/setOpmerking', value)
      }
    }
  }
}
</script>

<style scoped>
#opmerking {
  border: none;
  border-radius: 10px;
}

</style>
